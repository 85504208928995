module.exports = {
  pathPrefix: '/',

  siteTitle: 'Algebra Engineering',
  siteTitleAlt: 'Algebra Engineering',
  siteTitleManifest: 'Algebra Blog',
  siteUrl: 'https://blog.algebra.global',
  siteLanguage: 'en',
  siteHeadline: 'Writing and publishing content',
  siteBanner: '/social/banner.png',
  favicon: 'src/favicon.png',
  siteDescription: 'Engineering ideas behind Algebra.',
  author: 'xerdink',
  siteLogo: '/social/logo.png',

  userTwitter: '@xerdink',
  ogSiteName: 'xerdink',
  ogLanguage: 'en_US',
  googleAnalyticsID: 'UA-47519312-6',

  // Manifest and Progress color
  // See: https://developers.google.com/web/fundamentals/web-app-manifest/
  themeColor: '#3498DB',
  backgroundColor: '#2b2e3c',
}
